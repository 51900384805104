<template>
  <div class="secondskill">
    <!-- bg -->
    <div class="secondskill-bg">
      <img src="../../assets/img/secondskill/bg.png" alt="">
      <div class="secondskill-bg-banner">
        <van-swipe :autoplay="3000" class="secondskill-bg-banner-swiper">
          <van-swipe-item v-for="image in bannerList" :key="image" @click="goLinkFunc(image)">
            <img :src="image" class="secondskill-bg-banner-swiper-img"/>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="secondskill-main">
      <div class="flex flex-between secondskill-one">
        <div class="secondskill-one-item" v-for="(item, index) in activityInfoList" :key="item.id" :class="isChecked == index ? 'secondskill-one-item1' : ''" @click="selectTagFunc(index)">
          <div class="secondskill-one-item-time">{{item.activityStartTime.substring(11, 16)}}</div>
          <div class="secondskill-one-item-name">{{item.activityName}}</div>
          <div class="flex flex-center align-center secondskill-one-item-btn" v-if="item.status == 2" :class="isChecked == index ?'secondskill-one-item-btn1' : ''">
            <span class="secondskill-one-item-btn-color" :class="isChecked == index ?'secondskill-one-item-btn-color1' : ''">未开始</span>
          </div>
          <div class="flex flex-center align-center secondskill-one-item-btn" v-if="item.status == 3" :class="isChecked == index ?'secondskill-one-item-btn0' : ''">
            <span class="secondskill-one-item-btn-color" :class="isChecked == index ? 'secondskill-one-item-btn-color1' : ''">抢购中</span>
          </div>
          <div class="flex flex-center align-center secondskill-one-item-btn" v-if="item.status == 4" :class="isChecked == index ?'secondskill-one-item-btn2' : ''">
            <span class="secondskill-one-item-btn-color" :class="isChecked == index ?'secondskill-one-item-btn-color1' : ''">已结束</span>
          </div>
        </div>
      </div>
      <div class="flex flex-center align-center secondskill-two">
        <span class="secondskill-two-msg" v-if="activityStatus == 0">距开始还剩</span>
        <span class="secondskill-two-msg" v-if="activityStatus == 1">距结束还剩</span>
        <span class="secondskill-two-msg" v-if="activityStatus == 2">该抢购专场已结束</span>
        <span class="secondskill-two-time">{{currentTime.hours}}</span>
        <span class="secondskill-two-fg">:</span>
        <span class="secondskill-two-time">{{currentTime.minutes}}</span>
        <span class="secondskill-two-fg">:</span>
        <span class="secondskill-two-time">{{currentTime.seconds}}</span>
      </div>
      <div class="secondskill-list" v-for="item in goodsList" :key="item.id">
        <div class="secondskill-three">
          <div class="flex flex-between align-center secondskill-three-header">
            <span class="secondskill-three-header-l">
              {{item.title}}
            </span>
            <div class="flex flex-right align-center secondskill-three-header-r">
              <img src="../../assets/img/secondskill/zc.png" alt="">种草
            </div>
          </div>
          <div class="flex flex-between secondskill-three-goods">
            <div class="secondskill-three-goods-img">
              <img :src="item.goodPic" alt="">
            </div>
            <div class="secondskill-three-goods-info">
              <div class="secondskill-three-goods-info-title">
                <div class="secondskill-three-goods-info-title-tag" :style="{background: 'url('+require('../../assets/img/goods/goods-tag.png')+')no-repeat center center',backgroundSize: 'cover', marginRight: '5px'}">{{item.tag?item.tag: '正品保障'}}</div>{{item.goodName}}
              </div>
              <div class="flex flex-right align-center secondskill-three-goods-info-stork">
                <span v-if="(selectStatus == 3 && !item.percent) || selectStatus == 2">{{item.storeNum}}库存</span>
                <div class="secondskill-three-goods-info-stork-process" :style="{width: parseInt(item.percent/item.storeNum*100)+'%'}" v-if="item.percent">
                  <span v-if="item.percent == item.storeNum">已抢光</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;<img src="../../assets/img/process.png" alt=""></div>
              </div>
              <div class="secondskill-three-goods-info-price">
                <span class="secondskill-three-goods-info-price-span1">抢购价 ¥</span>
                <span class="secondskill-three-goods-info-price-span2">{{parseFloat(item.goodSeckillPrice/100).toFixed(2)}}</span>
                <span class="secondskill-three-goods-info-price-span3">¥{{parseFloat(item.goodPrice/100).toFixed(2)}}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-between align-center secondskill-three-jf">
            <div class="flex flex-left flex-wrap secondskill-three-jf-tag">
              <span class="secondskill-three-jf-tag-item">
                会员得 {{item.score}} 龙珠
              </span>
              <span class="secondskill-three-jf-tag-item">
                达人得 {{item.wiseScore}} 龙珠
              </span>
              <span class="secondskill-three-jf-tag-item secondskill-three-jf-tag-item1">
                推广返佣 ¥{{parseFloat(item.returnAmount/100).toFixed(2)}}
              </span>
            </div>
            <div class="flex flex-center align-center secondskill-three-jf-btn" v-if="selectStatus == 2" @click="goPageGoodsDetailFunc(item.goodId)">预约通知</div>
            <div class="flex flex-center align-center secondskill-three-jf-btn secondskill-three-jf-btn0" v-if="selectStatus == 3" @click="goPageGoodsDetailFunc(item.goodId)">马上抢购</div>
            <div class="flex flex-center align-center secondskill-three-jf-btn secondskill-three-jf-btn1" v-if="item.percent == item.storeNum" @click="goPageGoodsDetailFunc(item.goodId)">已抢光</div>
            <div class="flex flex-center align-center secondskill-three-jf-btn secondskill-three-jf-btn1" v-if="selectStatus == 4" @click="goPageGoodsDetailFunc(item.goodId)">已结束</div>
          </div>
          <div class="secondskill-three-line"></div>
        </div>
        <div class="secondskill-four">
          <div class="flex flex-between align-center secondskill-four-header">
            <div class="secondskill-four-header-l">
              <img :src="item.brandIcon" alt="">{{item.brandName}}
            </div>
            <div class="secondskill-four-header-r">
              <img src="../../assets/img/secondskill/right.png" alt="">
            </div>
          </div>
          <div class="secondskill-four-info" v-if="item.clerk">
            {{item.clerk}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>