import {
    activityList,
} from '@/utils/goods.js';
export default {
    data() {
        return {
            activityInfoList: '',
            isChecked: 0,
            goodsList: [],
            activityId: '',
            selectStatus: '',
            activityStatus: '',
            currentTime: {
                hours: '00',
                minutes: '00',
                seconds: '00'
            },
            timer: null,
            activityInfo: '',
            bannerList: [
                // require('../../assets/img/secondskill/skill-banner.png')
                'http://sfwx.cn-sh2.ufileos.com/banner/skill-banner.png'
            ]
        }
    },
    created() {
        this.getSecondsKillListFunc()
    },
    methods: {
        // 获取活动商品列表
        getSecondsKillListFunc() {
            activityList({}).then(res => {
                console.log('列表11', res.data)
                let aData = res.data.activityInfoList;
                aData.forEach((item, index) => {
                    if (item.status == 3) {
                        this.isChecked = index;
                        this.goodsList = item.goodInfoList;
                        this.selectStatus = item.status;
                        this.activityId = item.activityId;
                        this.activityInfo = item;
                        this.computedFunc(item);
                    }
                })
                this.activityInfoList = aData;
            })
        },
        // 计算活动结束时间
        computedFunc(data) {
            let startTime = new Date(data.activityStartTime).getTime();
            let endTime = new Date(data.activityEndTime).getTime();
            let nowTime = new Date().getTime();
            let isActivityStatus = 0;

            // 活动未开始
            if (startTime > nowTime) {
                isActivityStatus = 0;
                this.currentDownTimeFunc(startTime, nowTime, endTime, isActivityStatus);
            } else if (nowTime <= endTime) {
                // 活动进行中
                isActivityStatus = 1;
                this.currentDownTimeFunc(startTime, nowTime, endTime, isActivityStatus);
            } else {
                // 活动已结束
                isActivityStatus = 2;
            }
            clearTimeout(this.timer);
            this.activityStatus = isActivityStatus;
            this.timer = setTimeout(() => {
                this.computedFunc(data)
            }, 1000)
        },
        // 倒计时
        currentDownTimeFunc(stime, ntime, etime, status) {
            let pTime = 0;
            if (status == 0) {
                pTime = parseInt(stime - ntime);
            } else if (status == 1) {
                pTime = parseInt(etime - ntime);
            }

            let hours = Math.floor(pTime / 1000 / 60 / 60 % 24);
            let minutes = Math.floor(pTime / 1000 / 60 % 60);
            let seconds = Math.floor(pTime / 1000 % 60);

            console.log('hours:' + hours, 'minutes:' + minutes, 'seconds: ' + seconds)
            this.currentTime = {
                hours: this.addZeroFunc(hours),
                minutes: this.addZeroFunc(minutes),
                seconds: this.addZeroFunc(seconds)
            }
        },
        // 补0
        addZeroFunc(data) {
            return data >= 10 ? data : '0' + data;
        },
        // 切换选中的活动
        selectTagFunc(idx) {
            this.isChecked = idx;
            this.goodsList = this.activityInfoList[idx].goodInfoList;
            this.selectStatus = this.activityInfoList[idx].status;
            this.activityId = this.activityInfoList[idx].activityId;
            this.activityInfo = this.activityInfoList[idx];
            this.computedFunc(this.activityInfoList[idx]);
        },
        // 跳转到商品详情
        goPageGoodsDetailFunc(goodsid) {
            // status=2(预热)，status=3(进行中)，status=4(已结束)
            clearTimeout(this.timer);
            sessionStorage.setItem('skill', JSON.stringify(this.activityInfo));
            this.$router.push({
                path: '/secondsKillGoodsDetail',
                query: {
                    goodsId: goodsid,
                    activityId: this.activityId
                }
            })
        }
    },
}